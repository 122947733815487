<template>
  <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8359 9.02124L10.6328 14.2244L8.38281 12.0212C7.85156 11.5837 7.32031 11.5837 6.78906 12.0212C6.35156 12.5525 6.35156 13.0837 6.78906 13.615L9.78906 16.615C10.0703 16.8337 10.3516 16.9431 10.6328 16.9431C10.9453 16.9431 11.2109 16.8337 11.4297 16.615L17.4297 10.615C17.8672 10.0837 17.8672 9.55249 17.4297 9.02124C16.8984 8.58374 16.3672 8.58374 15.8359 9.02124ZM12.1328 0.818115C9.88281 0.849365 7.86719 1.39624 6.08594 2.45874C4.27344 3.52124 2.83594 4.95874 1.77344 6.77124C0.710938 8.55249 0.164062 10.5681 0.132812 12.8181C0.164062 15.0681 0.710938 17.0837 1.77344 18.865C2.83594 20.6775 4.27344 22.115 6.08594 23.1775C7.86719 24.24 9.88281 24.7869 12.1328 24.8181C14.3828 24.7869 16.3984 24.24 18.1797 23.1775C19.9922 22.115 21.4297 20.6775 22.4922 18.865C23.5547 17.0837 24.1016 15.0681 24.1328 12.8181C24.1016 10.5681 23.5547 8.55249 22.4922 6.77124C21.4297 4.95874 19.9922 3.52124 18.1797 2.45874C16.3984 1.39624 14.3828 0.849365 12.1328 0.818115ZM12.1328 22.5681C9.38281 22.5056 7.08594 21.5525 5.24219 19.7087C3.39844 17.865 2.44531 15.5681 2.38281 12.8181C2.44531 10.0681 3.39844 7.77124 5.24219 5.92749C7.08594 4.08374 9.38281 3.13062 12.1328 3.06812C14.8828 3.13062 17.1797 4.08374 19.0234 5.92749C20.8672 7.77124 21.8203 10.0681 21.8828 12.8181C21.8203 15.5681 20.8672 17.865 19.0234 19.7087C17.1797 21.5525 14.8828 22.5056 12.1328 22.5681Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCircle'
}
</script>
